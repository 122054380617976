import React, { useEffect, useMemo } from 'react'
import PlanningPanel from '../bookings/PlanningPanel'
import TabbedPage from '../_library/TabbedPage'
import { compact } from 'lodash'
import TrainingPanel from './TrainingPanel'
import ActivityDetails from './ActivityDetails'
import { useMatch } from 'react-router-dom'
import { ACTIVITIES } from '../../_constants/routes'
import { useDispatch } from 'react-redux'
import { selectActivityId } from '../../store/actions/dataActions'
import { ACTIVITY_COLLECTION, AVAILABILITY_COLLECTION, CHAT_COLLECTION, QUALIFICATION_COLLECTION, REQUEST_COLLECTION, SLOT_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const ActivityPage = () => {
  
  const match = useMatch(ACTIVITIES + '/:id')
  const dispatch = useDispatch()
  const activityHooks = useFirestore(ACTIVITY_COLLECTION)
  const dbActivities = activityHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const slotHooks = useFirestore(SLOT_COLLECTION)
  const requestHooks = useFirestore(REQUEST_COLLECTION)
  const chatHooks = useFirestore(CHAT_COLLECTION)
  const qualificationHooks = useFirestore(QUALIFICATION_COLLECTION)
  
  const activity = useMemo(() => dbActivities?.find(b => b.id === match?.params.id) || {}, [dbActivities, match?.params.id])
  
  useEffect(() => {
    activityHooks.listen({
      orderBy: [['firstDayDate', 'asc']],
    })
    qualificationHooks.listen({
      where: [['archived', '!=', true]],
      orderBy: [['archived', 'asc'], ['type.order', 'asc'], ['_createdAtTime', 'desc']],
    })
    return () => {
      activityHooks.unsubscribe()
      qualificationHooks.unsubscribe()
    }
  }, [])
  
  useEffect(() => {
    if (activity.id) {
      slotHooks.listen({
        where: [['activityRef', '==', activityHooks.getDocRef(activity.id)]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      requestHooks.listen({
        where: [['activityRef', '==', activityHooks.getDocRef(activity.id)]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      chatHooks.listen({
        where: [['activityRef', '==', activityHooks.getDocRef(activity.id)]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      
      return () => {
        slotHooks.unsubscribe()
        requestHooks.unsubscribe()
        chatHooks.unsubscribe()
      }
    }
  }, [activity.id])
  
  useEffect(() => {
    if (activity?.firstDayDate) {
      availabilityHooks.listen({
        where: [['dayDate', '>=', activity.firstDayDate]],
        orderBy: [['dayDate', 'asc']],
        endAt: activity.lastDayDate,
      })
      return availabilityHooks.unsubscribe
    }
  }, [activity.firstDayDate, activity.lastDayDate])
  
  useEffect(() => {activity && dispatch(selectActivityId(activity.id))}, [activity.id])
  
  const tabs = useMemo(() => compact([
    {
      key: 'list',
      label: 'Stages',
      title: 'Prochains stages',
      route: 'list',
      content: <TrainingPanel />,
    },
    {
      key: 'planning',
      label: 'Planning',
      route: 'planning',
      content: <PlanningPanel />,
    },
    {
      key: 'training',
      label: '',
      title: activity.title || '',
      route: '/:id',
      content: <ActivityDetails />,
    },
  ]), [activity])
  
  return <TabbedPage tabs={tabs} />
}

export default ActivityPage
