import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Select, MenuItem, Box } from '@mui/material'
import { addMonths, endOfYear, format, isThisYear, startOfYear } from 'date-fns'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import EditFlightDialog from './EditFlightDialog'
import { useFirestore } from '../../hooks/useFirestore'
import { FLIGHT_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'
import useAuth from '../../hooks/useAuth'


const LogbookTable = () => {
  
  const flightHooks = useFirestore(FLIGHT_COLLECTION)
  const dbFlights = flightHooks.getDocs()
  const selectedUserId = useSelector(state => state.data.selectedUserId)
  const profile = useAuth().getProfile()
  
  const [selectedFlight, selectFlight] = useState(null)
  const [year, setYear] = useState((new Date()).getFullYear())
  const [month, setMonth] = useState((new Date()).getMonth())
  
  const flights = useMemo(() => dbFlights?.filter(f =>
    selectedUserId
      ? f.pilotRef.id === selectedUserId || f.instructorRef?.id === selectedUserId
      : f.pilotRef.id === profile.id || f.instructorRef?.id === profile.id,
  ), [dbFlights, selectedUserId, profile.id])
  
  useEffect(() => {
    if (year) {
      flightHooks.listen(pickBy({
        orderBy: [['startDate', 'asc']],
        startAt: typeof year === 'number' && startOfYear((new Date()).setFullYear(year) || new Date()),
        endAt: typeof year === 'number' && endOfYear((new Date()).setFullYear(year) || new Date()),
      }))
      return flightHooks.unsubscribe
    }
  }, [year])
  
  const years = useMemo(() => [...Array((new Date()).getFullYear() - 2017 + 1).keys()].map(y => y + 2017), [])
  const months = useMemo(() => [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ], [])
  const filteredFlights = useMemo(
    () => flights?.filter(f => f.startDate > new Date(year, month) && f.startDate < addMonths(new Date(year, month), 1)),
    [flights, year, month],
  )
  const data = useMemo(
    () => filteredFlights?.map( val => {
      const { startDate, aircraft, pilot, instructor, flightType, duration, comments, landings } = val
      return [
        val,
        startDate,
        aircraft && aircraft.name,
        pilot ? (pilot.firstname + ' ' + pilot.lastname) : '',
        instructor ? (instructor.firstname + ' ' + instructor.lastname) : '',
        flightType && flightType.name,
        duration.toFixed(2),
        landings,
        comments || '',
      ]
    }),
    [filteredFlights],
  )
  
  if (!flights) return <Loading />
  return (
    <Box sx={{ m: { xs: 0, sm: 2 } }}>
      <Select
        variant='standard'
        id='year'
        value={year}
        onChange={e => setYear(e.target.value)}
        sx={{ ml: 2, mb: { xs: 0, sm: 2 } }}
      >
        {years.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
      </Select>
      <Select
        variant='standard'
        id='month'
        value={month}
        onChange={e => setMonth(e.target.value)}
        sx={{ ml: 2, mb: { xs: 0, sm: 2 } }}
      >
        {months.map((month, key) => <MenuItem key={key} value={key}>{month}</MenuItem>)}
      </Select>
      <MUIDataTable
        data={data}
        columns={[
          { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
          {
            name: 'Date',
            options: {
              filter: true,
              sort: true,
              customBodyRender: val => format(val, 'dd-MM-yyyy HH:mm'),
            },
          },
          { name: 'Avion', options: { filter: true, sort: true } },
          { name: 'Pilote', options: { filter: true, sort: true } },
          { name: 'Instructeur', options: { filter: true, sort: true } },
          { name: 'Type de vol', options: { filter: true, sort: true } },
          { name: 'Durée', options: { filter: true, sort: true } },
          { name: 'Atterrissages', options: { filter: true, sort: true } },
          { name: 'Commentaires', options: { filter: true, display: 'false' } },
        ]}
        options={{
          downloadOptions: {
            filename: `flights-${year}-${month}.csv`,
            filterOptions: {
              useDisplayedColumnsOnly: false,
              useDisplayedRowsOnly: true,
            },
          },
          onDownload: (buildHead, buildBody, columns, data) => {
            let res = ''
            data.forEach(row => {
              const model = (immat => {
                switch (immat) {
                  case 'F-HXAL': return 'EA330'
                  case 'F-BXHU': return 'CAP10'
                  case 'F-GLJG': return 'E200'
                  default: return ''
                }
              })(row.data[2])
              const fonction = row.data[4] === 'Romain COEUR' ? 'I' : 'P'
              const type = (flightType => {
                if (flightType.includes('Entrainement')) return 'voltige'
                else return flightType
              })(row.data[5])
              const duration = Math.trunc(row.data[5]) + ':' + ('0' + Math.round((row.data[6] % 1) * 60)).slice(-2)
              res += `${row.data[1].slice(0, 10)},Avion,${model},${row.data[2]},${fonction},${type},,,${duration}\n`
            })
            return res
          },
          selectableRows: 'none',
          search: false,
          onRowClick: (row, { dataIndex }) =>
            !filteredFlights[dataIndex].validated && isThisYear(row[0].startDate) && selectFlight(dataIndex),
          rowHover: false,
          setRowProps: (row, dataIndex) => ({
            sx: !filteredFlights[dataIndex].validated && isThisYear(row[0].startDate) && {
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            },
          }),
        }}
      />
      {selectedFlight !== null && <EditFlightDialog flight={filteredFlights[selectedFlight]} onClose={() => selectFlight(null)} />}
    </Box>
  )
}

export default LogbookTable
